import JSEncrypt from 'jsencrypt/bin/jsencrypt'

// const _import = require('@/router/_import_' + process.env.NODE_ENV);
/**
 * @param {string} path
 * @returns {Boolean}
 */
// export function isExternal(path) {
//     return /^(https?:|mailto:|tel:)/.test(path)
// }
// 加密方法
export function Encryption(params){
    let encryptor = new JSEncrypt()
    let publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCC0hrRIjb3noDWNtbDpANbjt5I
    wu2NFeDwU16Ec87ToqeoIm2KI+cOs81JP9aTDk/jkAlU97mN8wZkEMDr5utAZtMV
    ht7GLX33Wx9XjqxUsDfsGkqNL8dXJklWDu9Zh80Ui2Ug+340d5dZtKtd+nv09QZq
    GjdnSp9PTfFDBY133QIDAQAB`
    encryptor.setPublicKey(publicKey)
    return encryptor.encrypt(params)
}
export function checkStrong(sValue) {
    var modes = 0;
    //正则表达式验证符合要求的
    if (sValue.length < 1) return modes;
    if (/\d/.test(sValue)) modes++; //数字
    if (/[a-z]/.test(sValue)) modes++; //小写
    if (/[A-Z]/.test(sValue)) modes++; //大写
    if (/\W/.test(sValue)) modes++; //特殊字符
    
   //逻辑处理
    switch (modes) {
        case 1:
            return 1;
            break;
        case 2:
            return 2;
        case 3:
        case 4:
            return sValue.length < 12 ? 3 : 4
            break;
    }
}

// vant  DropdownMenu 下拉菜单 数据处理
export function DropdownMenuDate(data,name='Code_Name',value='Code_Value') {
    var res = []
    data.forEach(element => {
        res.push({
            text:element[name],
            value:element[value]
        })
    });
    return res
}

// 导出文件  文件ajax 下载推流
// export function Export_Download(url,option){
//     Api_Export_Download(url,option).then(res=>{
//         let reader = new window.FileReader()
//         reader.readAsArrayBuffer(res.data)
//         reader.onload = function (e) {
//           const result = e.target.result
//           const contentType =  res.data.type
//           const blob = new Blob([result], { type: contentType })
//           const url = window.URL.createObjectURL(blob)
//           var filename =  decodeURI(escape(res.headers.filename));  
//           var downloadElement = document.createElement('a');
//           var href = window.URL.createObjectURL(blob); //创建下载的链接
//           downloadElement.style.display = 'none';
//           downloadElement.href = url;
//           downloadElement.download =filename ; //下载后文件名
//           document.body.appendChild(downloadElement);
//           downloadElement.click(); //点击下载
//           document.body.removeChild(downloadElement); //下载完成移除元素
//           window.URL.revokeObjectURL(href); //释放掉blob对象
//       }
//     })  
// }